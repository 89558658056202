import TableSettings from "@/components/TableSettings.vue";

let ResourceView = {
    components: {
        TableSettings,
    },

    data() {
        return {
            sortBy: null,
            sortDir: false,
        }
    },

    methods: {
        sortItems(column) {
            if (this.sortBy === column) {
                this.sortDir = !this.sortDir;
            } else {
                this.sortBy = column;
                this.sortDir = false;
            }

            this.$router.push({
                query: {
                    ...this.$route.query,
                    sortBy: this.sortBy,
                    sortDir: this.sortDir ? 'true' : 'false'
                }
            });
        },
    },
}

export default ResourceView;
