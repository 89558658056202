<script>
import SelectInput from "@/components/Form/SelectInput.vue";
import TextInput from "@/components/Form/TextInput.vue";
import DatetimePicker from "@/components/DatetimePicker.vue";
import {mapGetters} from "vuex";

export default {
    name: 'FilterField',
    components: {DatetimePicker, SelectInput, TextInput},
    model: [
        'value',
        'operand'
    ],
    watch: {
        $route() {
            this.getRelations();
        }
    },
    computed: {
        ...mapGetters(['lang']),
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        name: {
            type: String
        },
        operand: {
            type: String
        },
        value: {
            type: [String, Number, Boolean, Array, Object]
        }
    },
    data() {
        return {
            menu: false,
            enums: []
        }
    },
    methods: {
        getOperands() {
            let operands = [];

            for (let i in this.field.operands) {
                let operand = this.field.operands[i];
                operands.push({
                    text: this.$t('filter.' + operand.label),
                    value: operand.key,
                });
            }

            return operands;
        },

        async getRelations() {
            if (this.field.type === 'relation') {
                let params = {};

                // Обрабатываем url так, чтобы все вхождения типа #param_name# были заменены на значения из this.$route.params или любых других дополнительных параметров
                let url = this.field.api_url;
                const regex = /#[a-zA-Z0-9_-]+#/g;
                let m;
                let replace = this.$route.params;
                replace.locale = this.$i18n.locale;

                while ((m = regex.exec(url)) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }

                    // The result can be accessed through the `m`-variable.
                    m.forEach((match, groupIndex) => {
                        url = url.replaceAll(match, replace[match.replaceAll('#', '')]);
                    });
                }

                await this.$http
                    .get(url, {
                        params: params,
                    })
                    .then(res => {
                        this.enums = [];
                        for (let i in res.body.data) {
                            this.enums.push({
                                text: res.body.data[i].name,
                                value: res.body.data[i].id + ""
                            })
                        }
                    })
                    .catch(err => {
                        this.enums = []
                    })
                    .finally(end => {
                        // this.loading = false
                    })
            } else {
                for (let i in this.field.enums) {
                    this.enums.push({
                        text: this.$t(this.field.enums[i].name),
                        value: this.field.enums[i].id + ""
                    })
                }
            }
        },

        onChangeCheckbox($event) {
            let result = undefined;

            if ($event === '1' || $event === true || $event === 'true' || $event === 1) {
                result = '1';
            }

            this.$emit('input', result)
        },

        onMultipleSelectChange(value) {
            if (!value || !(value instanceof Array)) {
                value = [];
            }

            value = value.filter(item => {
                return item !== null && item !== undefined && item !== '';
            });

            this.$emit('input', value)
        },

        onSelectChange(value) {
            if (value === null) {
                value = undefined;
            }

            this.$emit('input', value)
        },

        onOperandChange(value) {
            if (this.field.type === 'enum' || this.field.type === 'relation') {
                if (value === 'in' || value === 'notin') {
                    if (!(this.value instanceof Array)) {
                        this.$emit('input', [this.value])
                    }
                } else {
                    if (this.value instanceof Array) {
                        this.$emit('input', this.value[0])
                    }
                }
            }

            this.$emit('changeOperand', value);
        }
    },
    mounted() {
        this.getRelations();
    }
}
</script>

<template>
    <div class="filter-field">
        <template v-if="field.type === 'boolean'">
            <div class="filter-field__inner">
                <div class="filter-field__operand" v-if="getOperands().length > 1">
                    <v-select
                        :items="getOperands()"
                        outlined
                        solo
                        flat
                        dense
                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                        :value="operand"
                        :name="name + '_operand'"
                        @input="$emit('changeOperand', $event)"
                    ></v-select>
                </div>
                <div class="filter-field__input">
                    <v-checkbox
                        class="ma-0 pa-0 my-0 py-0"
                        :name="name"
                        :input-value="value"
                        true-value="1"
                        :label="$t('filter.field.' + field.label)"
                        hide-details
                        @change="onChangeCheckbox($event)"
                    />
                </div>
                <div class="filter-field__delete">
                    <v-btn
                        icon
                        @click="$emit('onDeleteField', name)"
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>
        <template v-else-if="field.type === 'datetime'">
            <div class="filter-field__label"><label class="input_label" :for="field.name">{{ $t('filter.field.' + field.label) }}</label></div>
            <div class="filter-field__inner">
                <div class="filter-field__operand" v-if="getOperands().length > 1">
                    <SelectInput
                        :items="getOperands()"
                        :value="operand"
                        :name="name + '_operand'"
                        :id="name + 'field-operand'"
                        hide-details
                        hide-label
                        label=""
                        @input="onOperandChange($event)"
                    />
                </div>
                <div class="filter-field__input">
                    <datetime-picker
                        :value="value"
                        format="24hr"
                        timeFormat="HH:mm"
                        readonly
                        hide-details
                        outlined
                        solo
                        flat
                        dense
                        color="primary"
                        class="input_text"
                        clearable
                        :label="$t('filter.field.' + field.label)"
                        :min-date-time="$moment().format('YYYY-MM-DD')"
                        :timezone="$auth.user().timezone"
                        :locale="lang"
                    ></datetime-picker>
                </div>
                <div class="filter-field__delete">
                    <v-btn
                        icon
                        @click="$emit('onDeleteField', name)"
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>
        <template v-else-if="field.type === 'enum' || field.type === 'relation'">
            <div class="filter-field__label"><label class="input_label" :for="field.name">{{ $t('filter.field.' + field.label) }}</label></div>
            <div class="filter-field__inner">
                <div class="filter-field__operand" v-if="getOperands().length > 1">
                    <SelectInput
                        :items="getOperands()"
                        :value="operand"
                        :name="name + '_operand'"
                        :id="name + 'field-operand'"
                        item-text="text"
                        item-value="value"
                        @input="onOperandChange($event)"
                        hide-details
                        hide-label
                        label=""
                    />
                </div>
                <div class="filter-field__input">
                    <template v-if="operand == 'in' || operand == 'notin'">
                        <SelectInput
                            :items="enums"
                            clearable
                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                            :name="name"
                            :label="$t('filter.field.' + field.label)"
                            :id="field.name"
                            item-text="text"
                            item-value="value"
                            hide-details
                            hide-label
                            multiple
                            :value="value instanceof Array ? value : []"
                            @input="onMultipleSelectChange($event)"
                        ></SelectInput>
                    </template>
                    <template v-else>
                        <SelectInput
                            clearable
                            item-title="text"
                            item-value="value"
                            hide-details
                            hide-label
                            :items="enums"
                            :value="value"
                            :name="name"
                            :label="$t('filter.field.' + field.label)"
                            :id="field.name"
                            @input="onSelectChange($event)"
                        ></SelectInput>
                    </template>
                    <!-- todo: combobox and multiple values when operan = in or notin -->
                </div>
                <div class="filter-field__delete">
                    <v-btn
                        icon
                        @click="$emit('onDeleteField', name)"
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="filter-field__label"><label class="input_label" :for="field.name">{{ $t('filter.field.' + field.label) }}</label></div>
            <div class="filter-field__inner">
                <div class="filter-field__operand" v-if="getOperands().length > 1">
                    <SelectInput
                        :items="getOperands()"
                        :value="operand"
                        :name="name + '_operand'"
                        :id="name + 'field-operand'"
                        hide-details
                        hide-label
                        label=""
                        @input="onOperandChange($event)"
                    />
                </div>
                <div class="filter-field__input">
                    <TextInput
                        :id="field.name"
                        :value="value"
                        :label="$t('filter.field.' + field.label)"
                        :name="name"
                        hideLabel
                        @input="$emit('input', $event)"
                    />
                </div>
                <div class="filter-field__delete">
                    <v-btn
                        icon
                        @click="$emit('onDeleteField', name)"
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.filter-field {
    display: flex;
    flex-direction: column;
    margin-left: $grid-gutter / -2;
    margin-right: $grid-gutter / -2;
}

.filter-field__input {
    flex-grow: 1;
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__label {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__operand {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__inner {
    flex-grow: 1;
    display: flex;
}

.filter-field__label {

}
</style>
