<script>
export default {
    name: "TableSettings",

    props: {
        fields: {
            type: Array,
            required: true
        },
        entityName: {
            type: String,
            required: true
        },
        listForm: {
            type: Object,
            required: false
        },
        className:{
            type:String,
            default:'',
        }
    },

    data() {
        return {
            selectTimeout: null,
            loading: false,
            dialog: false,
            activeColumns: []
        }
    },

    watch: {
        fields: {
            handler: function (value) {
                if (this.fields) {
                    this.activeColumns = this.fields
                        .filter((item) => {
                            return item.active
                        }).map((item) => {
                            return item.name
                        });
                } else {
                    this.activeColumns = []
                }
            },
            immediate: true
        }
    },

    methods: {
        selectField(column, value) {
            this.$emit('column-select', value, true)
        },
    },
}
</script>

<template>
    <v-menu
        v-model="dialog"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                x-small
                icon
                dark
                color="primary"
                :class="className"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>
                    icon-gear
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Настройки таблицы
            </v-card-title>
            <v-card-text>
                <div class="table-settings__item" v-for="(column, index) in fields" :key="column.name">
                    <v-checkbox
                        @change="selectField(column, $event)"
                        :disabled="loading"
                        :label="column.label"
                        :value="column.name"
                        multiple
                        v-model="activeColumns"
                        hide-details
                        class="ma-0 pa-0 table-settings"
                    >
                        <template v-slot:label>
                            {{ column.label }}
                        </template>
                    </v-checkbox>
                </div>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<style scoped>
.table-settings__item {
    display: flex;
}
</style>
