<template>
    <PageLayout>
        <template v-slot:side class="left_filter">
            <v-icon class="mt-2 mb-5" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            <v-list class="filter_left mt-5">
                <v-list-group :ripple="false" v-model="group1" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('system_filters')}}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" :to="item.url" :class="item.id === employee_position ? 'active' : ''">
                        <v-list-item-title v-text="item.name" :title="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list class="filter_left" v-if="Additional.length > 0">
                <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('additional')}}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list class="filter_left" v-if="departments.length > 0">
                <v-list-group :ripple="false" v-model="groupDepartments" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('admin_profile.departments')}}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in departments" :key="i" :to="item.url">
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list v-if="userFilters.length > 0" class="filter_left">
                <v-list-group :ripple="false" v-model="userFiltersGroup" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('user_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in userFilters" :key="i" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}" :active-class="'no-active'" :to="item.url">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
        <template>
            <v-form @submit.prevent="getItems('search')">
                <v-btn v-show="$vuetify.breakpoint.xsOnly && can(['administrator','director', 'hr_manager'])" :to="{name: 'administrator.create'}" color="secondary" fab fixed bottom right class="v-btn--add-form-top white--text">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <TabsNav />
                <div class="d-flex justify-space-between mb-10">
                    <div class="d-flex align-center">
                        <h1>{{ filter_show ? $t('search_administrator') : $t('administrator') }}</h1>
                    </div>
                    <div v-if="!$vuetify.breakpoint.xsOnly && can(['administrator','hr_manager'])" v-show="!filter_show" class="text-right">
                        <v-btn :to="{name: 'administrator.create'}" class="infinity_button" color="secondary">
                            {{$t('create') }}
                        </v-btn>
                    </div>
                </div>

                <FilterComponent
                    class="mb-10"
                    v-if="filter_show"
                    :filterData="filterData"
                    :currentFilter="currentFilter"
                    :value="filterValues"
                    :loading="loading"
                    entity="administrator"
                    @input="filterValues = $event"
                    @submit="submitForm"
                    @filterSaved="filterSaved"
                    @filterDeleted="filterDeleted"
                />
            </v-form>

            <ResizableTable
                class="main-data-table"
                :rows="administrators"
                :columns="filteredHeaders.map((item) => {
                    return {
                        ...item,
                        prop: item.value,
                        label: item.text,
                        width: 'auto',
                        active: true
                    }
                })"

                :sort-dir="sortDir"
                :sort-by="sortBy"

                @sort="sortItems"
            >
                <template v-slot:name="{ item }">
                    <div :class="item.deleted ? 'red--text' : ''">
                        <span  class="cursor-pointer font_weight_600" @click="editUser(item)" >
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </span>
                        <v-icon v-if="!item.deleted" @click="deleteAdminShow(item)" small>mdi-delete-outline</v-icon>
                    </div>
                </template>

                <template v-slot:phone="{ item }">
                    <div class="phone">
                        {{ formatPhoneNumber(item.phone) }}
                    </div>
                </template>

                <template v-slot:active="{ item }">
                    <div>{{ item.active ? $t('yes') : $t('no')}}</div>
                </template>
            </ResizableTable>
            <v-pagination v-model="options.page" :total-visible="totalVisiblePag" class="pagination_table" :length="options.pageCount" :disabled="loading" @input="onPageChange"></v-pagination>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="AdminListDialog"
                class="dialog_body"
            >
                <v-card>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('administrator_remove') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeAdminListDialog()" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <v-col cols="12">
                                <div class="pt-2 pb-4">{{$t('choose_manager_transfer_deals_tasks_clients')}}</div>
                                <ValidationProvider ref="assign_admin" rules="required" v-slot="{ errors, valid }">
                                    <v-select v-model="assign_admin" :disabled="loading"
                                              :items="adminItems" :error-messages="errors"
                                              :error="!valid"
                                              item-text="name" item-value="id"
                                              :label="$t('manager')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              hide-details
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                :disabled="invalid || loading" :loading="loading"
                                text
                                @click="deleteUser()"
                            >{{ $t('delete') }}</v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </PageLayout>
</template>
<script>
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import {mapGetters, mapActions} from "vuex"
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import FilterView from "@/plugins/mixins/FilterView";
    import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";
    import TabsNav from "@/components/Leentech/TabsNav.vue";
    import SortableView from "@/plugins/mixins/SortableView";

    export default {
        name: "Administrators",
        directives: {
            mask,
        },
        components: {
            TabsNav,
            ValidationProvider,
            ValidationObserver,
            PageLayout
        },

        mixins: [
            FilterView,
            SortableView
        ],

        data() {
            return {
                entityName: 'administrator',

                departments: [],
                options: {
                    ...FilterView.options,
                    sortBy: ['name'],
                    sortDir: [true],
                },

                fab: false,
                phoneRaw: null,
                administrator: null,
                email: null,
                administrators: [],
                administratorItems: [],
                active: 1,
                activeItems: [
                    {
                        id: null,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 0,
                        name: this.$t('no')
                    }
                ],
                totalVisiblePag: 5,
                totalAdministrators: 0,
                loading: false,
                headers: [
                    {
                        text: this.$t('user_name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('employee_position'),
                        align: "left",
                        value: "employee_position"
                    },
                    {
                        text: this.$t('email'),
                        align: "left",
                        sortable: true,
                        value: "email"
                    },
                    {
                        text: this.$t('phone'),
                        align: "left",
                        sortable: true,
                        value: "phone"
                    },
                    {
                        text: this.$t('active'),
                        align: "left",
                        sortable: true,
                        value: "active"
                    },
                ],
                employee_position: null,
                employee_positionItems: [],
                system_filters: [
                    {'title': 'Все контакты', 'count': '10'},
                    {'title': 'Мои клиенты', 'count': '0'},
                    {'title': 'Избранное', 'count': '7'},
                ],
                Additional: [],
                group1: true,
                group2: true,
                groupDepartments: true,
                filter_show: false,
                AdminListDialog: false,
                assign_admin: null,
                adminItems: [],
                delete_admin_id: null,
                userFiltersGroup: true
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask', 'formAdministrator']),

            phone: function () {
                return this.changePhone(this.phoneRaw)
            },

            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            }
        },

        methods: {
            ...mapActions(['setFormAdministrator']),

            init() {
                this.getEmployeePositions()
                this.getDepartments()
                this.getItems()
                this.getFilters()
            },

            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
            },

            editUser(item) {
                this.$router.push({
                    name: 'administrator.profile',
                    params: {
                        id: item.id
                    }
                })
            },

            async getEmployeePositions() {
                let params = {};
                params.filter = 'all'
                await this.$http
                    .get(`admin/employee_position`, {
                        params: params,
                    })
                    .then(res => {
                        this.employee_positionItems = res.body.data;
                        this.system_filters = res.body.data.map((item) => {
                            return {
                                ...item,
                                url: {
                                    path: '/administrator',
                                    query: {
                                        ...this.$route.query,
                                        filter: {
                                            employee_position_id: {
                                                eq: item.id + ''
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    })
                    .catch(err => {
                        this.employee_positionItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_employee_positions'))
                    })
            },

            async getItems(type) {
                this.loading = true
                const {
                    sortBy,
                    sortDir,
                    page,
                    itemsPerPage
                } = this.options;

                let params = {};

                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }

                if (sortDir[0] !== undefined) {
                    params.sortDir = sortDir[0] ? 'asc' : 'desc';
                }

                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }

                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }

                if (this.administrator) {
                    params.administrator = this.administrator
                }

                if (this.phone) {
                    params.phone = this.phone
                }

                if (this.email) {
                    params.email = this.email
                }

                if (this.employee_position) {
                    params.employee_position = this.employee_position
                }

                // params.active = this.active

                // if (type === undefined && page === 1 && this.formAdministrator) {
                //     params.administrator = this.formAdministrator.administrator
                //     params.email = this.formAdministrator.email
                //     params.phone = this.formAdministrator.phone
                //     params.active = this.formAdministrator.active
                //     params.sortDir = this.formAdministrator.sortDir
                //     params.sortBy = this.formAdministrator.sortBy
                //     params.perPage = this.formAdministrator.perPage
                //     params.page = this.formAdministrator.page
                // } else {
                //     this.setFormAdministrator({
                //             administrator: params.administrator,
                //             email: params.email,
                //             phone: params.phone,
                //             active: params.active,
                //             sortBy: params.sortBy,
                //             sortDir: params.sortDir,
                //             perPage: params.perPage,
                //             page: params.page,
                //         })
                // }

                if(this.filterValues) {
                    params.fltr = this.filterValues;
                }

                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.filterData = res.body.filter
                        this.administrators = res.body.data
                        this.totalAdministrators = res.body.meta.total

                        this.$set(this.options, 'page', res.body.meta.current_page)
                        this.$set(this.options, 'pageCount', res.body.meta.last_page)
                    })
                    .catch(err => {
                        this.administrators = []
                        this.totalAdministrators = 0
                        this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },

            closeAdminListDialog(){
                this.AdminListDialog  = false
                this.assign_admin = null
            },

            deleteAdminShow(item) {
                this.AdminListDialog  = true
                this.assign_admin = null
                this.delete_admin_id = item.id
            },

            async getAdmins() {
                this.loading = true
                let params = {};
                params.active = this.active
                params.perPage = 1000

                if (this.filterValues) {
                    params.fltr = this.filterValues;
                }

                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.adminItems = res.body.data
                        this.filterData = res.body.filter
                    })
                    .catch(err => {
                        this.adminItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },

            async deleteUser(item) {
                if (confirm(this.$t('delete_administrator'))) {
                    var _this = this
                    let params = {};
                    params.assign_admin = this.assign_admin;
                    this.loading = true
                    await this.$http
                        .delete(`admin/admin/${this.delete_admin_id}`,{
                            params: params
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('administrator_has_been_deleted'))
                            this.closeAdminListDialog()
                            this.getItems()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('administrator_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },

            getDepartments() {
                this.$http
                    .get("admin/department")
                    .then(res => {
                        let deps = res.body.data;

                        deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
                        deps = buildListArrayFromTreeArray(deps);

                        deps = deps.map((item) => {
                            item.url = {
                                path: '/administrator',
                                query: {
                                    ...this.$route.query,
                                    filter: {
                                        department_id: {
                                            eq: item.id + ''
                                        }
                                    }
                                }
                            }

                            let repeatCount = (item.depth ?? 1) - 1;
                            item.name = ('. '.repeat(repeatCount)) + item.name;

                            return item;
                        })

                        // adding top lvl
                        deps.unshift({
                            id: 0,
                            name: this.$t('departments.undistributed_admins'),
                            url: {
                                path: '/administrator',
                                query: {
                                    ...this.$route.query,
                                    filter: {
                                        department_id: {
                                            eq: '0'
                                        }
                                    }
                                }
                            }
                        })

                        this.departments = deps;
                    })
                    .catch(err => {
                        this.departments = []
                        this.$toastr.error(this.$t('failed_to_get_list_departments'))
                    })
            },
        }
    }
</script>
